<template>
  <main-view>
    <page-template>
      <h1>Privacy Policy</h1>
      <p>
        This web site is operated by the Responsive Environments Group at the
        MIT Media Lab.  Its purpose is the sharing of information about the
        Tidmarsh research project and the dissemination of research data.  We
        deliberately minimize the amount of data collected about our visitors.
        With the sole exception of anonymous aggregate statistics (e.g. total
        number of visitors) in academic theses and publications, we do not
        share information we collect, other than data provided to us by
        collaborators explicitly for the purpose of publication on the web
        site.
      </p>

      <h3>Cookies</h3>
      <p>
        When you visit the site, a unique identifier is stored on your computer.
        This is called a "cookie".  Save for a single exception explained below,
        this is used solely to recall any settings or customizations you have
        made on the web site (for example, your selection for the units used to
        display data).  This is optional; if you configure your browser not to
        accept cookies from this site, all features will continue to work, but
        your preferences will not be saved across site visits.
      </p>
      <p>
        We may occasionally use the cookie identifier to approximate the number
        of unique visitors to our site for publication in academic
        papers/theses.  
      </p>

      <h3>Voluntarily provided information</h3>
      <p>
        As described above, we may store the preferences you set on the web
        site, which will be associated with your cookie ID.  This information is
        not indended to be personally identifiable and is used solely to display
        the web site according to your preferences.
      </p>
      <p>
        If you have an account on the web site (i.e. you are a researcher
        collaborating on the project), the information you provide (e.g. project
        descriptions, photographs, project updates) will be stored on our server
        and, as you designate, displayed publicly on the web site.
      </p>
      <p>
        If you have an account on the site, you have the right to request a copy
        of or request the correction or deletion of all personal information we
        store.  If you do not have an account, we have no way to link such a
        request to any information.
      </p>

      <h3>Automatically collected information</h3>
      <p>
        When your web browser makes a request to the site, certain information
        about the request is stored in log files on the web server, as is
        standard practice.  This includes the date and time of the request, the
        URL requested, the web browser and operating system used, and the
        Internet Protocol (IP) address of the requesting computer.  We do not
        use this information for purposes other than troubleshooting problems
        with the web server and investigating abuse of the web site, nor do we
        share this information with any third party.  This information is
        generally not retained for more than three months, though these logs may
        continue to exist in backups indefinitely.
      </p>
    </page-template>
  </main-view>
</template>

<script>
  export default {
    name: 'Privacy'
  }
</script>

